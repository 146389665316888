// src/components/HeroSection.tsx
import React from 'react';
import styled from 'styled-components';

import bgImage from '../assets/hero_background.jpg'
import serviceImg1 from '../assets/icons/service1.svg';
import serviceImg2 from '../assets/icons/service2.svg';
import serviceImg3 from '../assets/icons/service3.svg';
import ImageCard from "./ImageCard";
import {Box, Card, Grid, Typography} from "@mui/material";

const items = [
  {
    title: "Профессиональный подход к делу",
    image: serviceImg1,
  },
  {
    title: "Гибкие условия сотрудничества, учитывая индивидуальные пожелания клиента",
    image: serviceImg2,
  }, {
    title: "Получение полного комплекса услуг",
    image: serviceImg3,
  },
  {
    title: "Квалифицированная процедура",
    image: serviceImg2,
  },
]

const HeroSection: React.FC = () => (
  <ImageCard backgroundImage={bgImage}
             sx={{
               py: "5rem",
               px: {lg: "0", sm: "1rem", xs: "1rem"},
               height: {lg: "95vh", md: "100%"},
               borderRadius: 0,
               justifyContent: "center",
             }}
             borderRadius="0"
             backgroundColors={['rgba(0, 0, 0, 0.11)', 'rgba(0,0,0,0.64)', 'rgba(0,0,0,0.68)']}>
    <Grid container sx={{
      width: "100%",
      paddingX: {lg: "10rem", md: "1rem", xs: "1rem"},
      display: "flex",
      justifyContent: "center",
      flexDirection: {md: "row", sm: "column"},
    }}
    >

      <Grid md={10.2} mb={{lg: 10, xs: 6}}>
        <Typography sx={{
          fontSize: {lg: "4rem", md: "3rem", sm: "2.5rem", xs: "1.5rem"},
          lineHeight: {lg: "4rem", md: "3rem", sm: "2.5rem", xs: "1.5rem"},
          textAlign: {md: "start", xs: "center"},
        }} fontWeight={500}>Помогаем бизнесу выйти на международный
          рынок</Typography>
      </Grid>
      <Grid container gap={3} sx={{
        display: "flex",
        flexDirection: {md: "row", sm: "column"}
      }} justifyContent="center">
        {items.map((item: any) => (
          <Grid item sx={{
            background: 'rgba(255,255,255,0.88)',
            color: "black",
            borderRadius: "1rem",
            display: "flex",
            padding: "2rem",
            gap: "3rem",
            justifyContent: {lg: "center", sm: "start", xs: "space-between"},
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)"
          }} md={5} xs={12}>
            <img style={{width: "5rem", alignSelf: "top"}} src={item.image} alt={item.title}/>
            <Grid display="flex" sx={{flexDirection: {md: "column", xs: "row"}}} justifyContent="center">
              <Typography variant="h6" sx={{
                fontSize: {lg: "1.3rem", md: "1rem", sm: "1.2rem", xs: "1rem"},
                alignContent: "center",
                width: "fit-content",
              }} fontWeight={600}>{item.title}</Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>

    </Grid>

  </ImageCard>
);

export default HeroSection;