// src/pages/Home.tsx
import React from 'react';
import Header from '../components/Header';
import HeroSection from "../components/HeroSection";
import StepsSection from "../components/StepsSection";
import PartnersSection from "../components/PartnersSection";
import CustomBrokerSection from "../components/CustomBrokerSection";
import CustomServicesSection from "../components/CustomServicesSection";
import CargoTransportationSection from "../components/CargoTransportationSection";
import BrokersSection from "../components/BrokersSection";
import NewsSection from "../components/NewsSection";
import FAQSection from "../components/FAQSection";

const Home: React.FC = () => (
  <>
    <Header />
    <div id="hero">
      <HeroSection />
    </div>
    <div id="steps">
      <StepsSection />
    </div>
    <div id="partners">
      <PartnersSection />
    </div>
    <div id="broker">
      <CustomBrokerSection />
    </div>
    <div id="services">
      <CustomServicesSection />
    </div>
    <CargoTransportationSection />
    <BrokersSection />
    <NewsSection />
    <FAQSection />
  </>
);

export default Home;
