// src/components/Header.tsx
import React from 'react';
import whatsappIcon from "../assets/icons/whatsapp.png";
import telegramIcon from "../assets/icons/telegram.webp";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from "@mui/material";

import logo from "../assets/icons/logo.png";
import {ReactComponent as MenuIcon} from "../assets/icons/Menu.svg";
function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages = [
    { name: 'Главная', link: 'hero' },
    { name: 'О компании', link: 'steps' },
    { name: 'Услуги', link: 'partners' },
    { name: 'Инфоцентр', link: 'broker' },
    { name: 'Контакты', link: 'services' },
  ];

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <AppBar position="static" variant="elevation" color="default">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={() => handleScroll(page.link)}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "center", gap: "1rem" }}>
            <Box sx={{ alignSelf: "center", mr: "9rem" }}>
              <img style={{ width: "5rem" }} src={logo} />
            </Box>

            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => handleScroll(page.link)}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: "flex" }}>
            <Box display="flex" gap={3}>
              <Typography sx={{display: {lg: "block", xs: "none"} }}>+7 (707) 707-07-77</Typography>
              <img src={whatsappIcon} style={{ width: "1.5rem" }} alt="WhatsApp" />
              <img src={telegramIcon} style={{ width: "1.5rem" }} alt="Telegram" />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
