// src/components/BrokersSection.tsx
import React from 'react';
import styled from 'styled-components';

import brokerImg2 from '../assets/broker2.png';
import brokerImg1 from '../assets/broker1.png';

import serviceIcon1 from '../assets/brokerIcons/icon1.svg'
import serviceIcon2 from '../assets/brokerIcons/icon2.svg'
import serviceIcon3 from '../assets/brokerIcons/icon3.svg'
import serviceIcon4 from '../assets/brokerIcons/icon4.svg'
import ImageCard from "./ImageCard";
import {Box, Button, Card, Grid, Typography} from "@mui/material";

const items = [
  {
    image: serviceIcon1,
    text: 'Гражданское',
  },
  {
    image: serviceIcon2,
    text: 'Таможенное',
  },
  {
    image: serviceIcon3,
    text: 'Валютное и налоговое',
  },
  {
    image: serviceIcon4,
    text: 'Международное право и Таможенный Союз',
  },
]

const BrokersSection: React.FC = () => (
  <Grid sx={{
    background: "#EDF6F0",
    paddingY: {lg: "4rem", xs: "1.5rem"},
    paddingX: {lg: "15rem", md: "4rem", xs: "1rem"},
    display: "flex",
    gap: {lg: 10, xs: 5},
    flexDirection: {lg: "row", xs: "column"},
  }} container justifyContent="center">
    <Grid lg={6}>
      <Typography textAlign="start" fontWeight={600} sx={{
        mb: {
          lg: "6rem",
          xs: "1rem",
        },
        fontSize: {lg: "2.5rem", md: "2rem", xs: "2rem"},
        lineHeight: {lg: "2.5rem", md: "2rem", xs: "2rem"},
      }}>Страховой брокер</Typography>
      <Typography textAlign="start" variant="h5" fontWeight={600} sx={{
        fontSize: {lg: "1.5rem", md: "1.3rem", xs: "1.2rem"},
        lineHeight: {lg: "1.5rem", md: "1.3rem", xs: "1.2rem"},
      }}>Мы знаем и учитываем требования
        законодательства</Typography>
      <Grid container sx={{
        mt: {lg: 6, xs: 2},
        flexDirection: {lg: "row", xs: "column"}
      }} gap={3}>
        {items.map((item: any) => (
          <Grid lg={5} justifyContent="center">
            <Card sx={{
              borderRadius: "1rem",
              height: {lg: "10rem", xs: ""},
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "1rem",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)"
            }}>
              <Box sx={{
                display: {lg: "block", xs: "none"}
              }}>
                <img style={{width: "3rem", alignSelf: "center"}} src={item.image} alt={item.text}/>
              </Box>
              <Typography sx={{
                mt: {lg: 2, xs: 0}
              }} alignSelf="center" fontWeight={600}>{item.text}</Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
    <Grid lg={4}>
      <ImageCard sx={{height: "85%"}} backgroundImage={brokerImg1}
                 backgroundColors={['rgba(0, 0, 0, 0.11)', 'rgba(0,0,0,0.43)', 'rgb(0, 0, 0)']}>
        <Box mt="auto">
          <Typography variant="h6" fontWeight={600}>Поддержка специалистов</Typography>
          <Box px="1rem" display="flex" mt={2} flexDirection="column" gap={1}>
            <Typography fontSize=".8rem">Юридические консультации по вопросам ВЭД и международного права</Typography>
            <Typography fontSize=".8rem">Представительство в судах при разрешении споров с таможенными
              органами</Typography>
            <Typography fontSize=".8rem">Аудит ВЭД, оптимизация внешнеэкономических контрактов и внешние техничные
              брокеры</Typography>
            <Typography fontSize=".8rem">Разработка специализированных договоров: таможенные представительства,
              транспортно-экспедиционное обслуживание, хранение товаров на СВХ, внешнеторговые контракты</Typography>
          </Box>
        </Box>
      </ImageCard>
    </Grid>

    <Grid container lg={12} sx={{
      flexDirection: {lg: "row", xs: "column"},
      gap: {lg: "5rem", xs: "2rem"},
    }} justifyContent="center">
      <Grid lg={6} sx={{
        height: {lg: "100%", xs: "20rem"}
      }}>
        <img style={{width: "100%", height: "100%", objectFit: "cover", borderRadius: "1rem"}} src={brokerImg2}
             alt="Insurance Broker"/>
      </Grid>
      <Grid lg={4} display="flex" gap={5} sx={{textAlign: "start"}} flexDirection="column" height="100%"
            justifyContent="end">
        <Typography sx={{
          fontSize: {lg: "2.5rem", md: "2rem", xs: "2rem"},
          lineHeight: {lg: "2.5rem", md: "2rem", xs: "2rem"},
        }} fontWeight={600}>Тендерный брокер</Typography>
        <Typography sx={{
          fontSize: {lg: "1.5rem", md: "1.3rem", xs: "1.2rem"},
          lineHeight: {lg: "1.5rem", md: "1.3rem", xs: "1.2rem"},
        }} fontWeight={600} mt={2}>Даже в самых сложных и нестандартных ситуациях помогаем
          получить</Typography>
        <Box display="flex" flexDirection="column" gap={2} pl={3}>
          <Typography>Свидетельство о государственной регистрации продукции</Typography>
          <Typography>Сертификаты и декларации соответствия техническому регламенту</Typography>
          <Typography>Ветеринарные и фитосанитарные документы (сертификаты, разрешения на ввоз)</Typography>
          <Typography>Экспертное заключение по состоянию и качеству товара</Typography>
          <Button variant="contained" color="success" sx={{p: "1rem", mt: 3, width: {lg: "70%", md: "70%", xs:"100%"}, ml: "auto", mr: {md: "auto", xs:"0"} }}
                  fullWidth={false}>Получить консультацию</Button>
        </Box>
      </Grid>
    </Grid>
  </Grid>
);

export default BrokersSection;
