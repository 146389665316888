// src/components/FAQSection.tsx
import React, {useState} from 'react';
import styled from 'styled-components';
import {Box, Button, Card, Grid, Input, TextField, Typography} from "@mui/material";

import logo from '../assets/icons/logo.png';
import bgImage from '../assets/faq_background.jpg';
import ImageCard from "./ImageCard";

const FAQAnswer = styled.div<{ isOpen: boolean }>`
  padding: 0 2rem;
  font-size: 1rem;
  color: #666;
  max-height: ${({isOpen}) => (isOpen ? '1000px' : '0')};
  opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;

const FAQItem: React.FC<{ question: string; answer: string; isOpen: boolean; onClick: () => void }> = (
  {
    question,
    answer,
    isOpen,
    onClick
  }) => {
  return (
    <Grid sx={{
      background: "white",
      padding: {md: "15px", xs: ".5rem"},
      marginBottom: "10px",
      borderRadius: "1rem",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
    }} onClick={onClick}>
      <Grid display="flex" justifyContent="space-between">
        <Typography alignSelf="center" fontWeight={600} sx={{
          px: {md: 4, xs: 2}
        }}>{question}</Typography>
        <Typography alignSelf="center" fontWeight={600} fontSize="1.5rem">{isOpen ? '-' : '+'}</Typography>
      </Grid>
      <FAQAnswer isOpen={isOpen}>
        {answer}
      </FAQAnswer>
    </Grid>
  );
};

const FAQSection: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <ImageCard borderRadius={{lg: "1rem 1rem 0 0", xs: "0"}} sx={{
      padding: {md: "3rem 2rem", xs: "1rem"},
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: {lg: "89vh", xs: "100vh"},
      maxHeight: "",
      flexDirection: "column",
      textAlign: "left",
      color: "black",
    }} backgroundColors={['rgba(0, 0, 0, 0.5)', 'rgb(0, 0, 0)']} backgroundImage={bgImage}>
      <Grid container sx={{
        display: "flex",
        gap: {lg: "1rem", xs: ".5rem"},
        width: "100%",
        maxWidth: "1200px",
        justifyContent: "space-around",
      }}
      >
        <Grid display="flex" flexDirection="column" lg={6}>
          <Typography sx={{
            fontSize: {lg: "3rem", md: "2rem", xs: "2rem"}
          }} fontWeight={600} mb={5} color="white"> Часто задаваемые вопросы</Typography>

          <Box flex={1}>
            {[
              'Что входит в услуги таможенного брокера?',
              'Таможенное оформление грузов: подготовка',
              'Как выбрать таможенного брокера: 4 совета',
              'Как не напортачить с КБК?',
              'Кто такие таможенные брокеры',
            ].map((question, index) => (
              <FAQItem
                key={index}
                question={question}
                answer="Услуги таможенного брокера насущная необходимость, если вы занимаетесь покупкой товаров из-за рубежа или отправкой за границу собственной продукции коммерческим партнёрам. Брокер не только подает от вашего лица декларацию в таможенный орган, но и консультирует вас, помогает и разрешает сложные ситуации на всём пути транспортировки товара."
                isOpen={openIndex === index}
                onClick={() => toggleFAQ(index)}
              />
            ))}
          </Box>
        </Grid>
        <Grid lg={3.5} sx={{
          mt: {md: 11, xs: 0}
        }}
        >
          <Card sx={{
            paddingY: "20px",
            paddingX: {md: "2rem", xs: "1rem"},
            borderRadius: "1rem",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: {md: "400px", xs:"93%"},
            height: "67vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}>
            <Grid display="flex" justifyContent="center">
              <img src={logo} style={{width: "30%"}} alt=""/>
            </Grid>
            <Typography textAlign="center" variant="h6" fontWeight={600}>ОСТАЛИСЬ ВОПРОСЫ? БЕСПЛАТНАЯ
              КОНСУЛЬТАЦИЯ</Typography>
            <Typography textAlign="center">
              Мы работаем только с юрлицами и ИП. Заполните форму и мы свяжемся с вами в течение 15 минут.
            </Typography>
            <Grid display="flex" flexDirection="column" gap={2} mb={2}>
              <TextField variant="outlined" type="text" label="Ваше имя"/>
              <TextField variant="outlined" type="text" label="Ваш телефон"/>
              <TextField variant="outlined" type="text" label="ИИН"/>
              <Button variant="contained" color="success" sx={{padding: "1rem"}} type="submit">Отправить</Button>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </ImageCard>
  );
};

export default FAQSection;
