// src/components/ImageCard.tsx
import React, { ReactNode } from 'react';
import { Box } from "@mui/material";

interface ImageCardProps {
  children?: ReactNode;
  backgroundImage?: string;
  backgroundColors?: string[];
  sx?: object;
  [key: string]: any;
  borderRadius?: string | any
}

const defaultBackgroundColors = ['rgba(0, 0, 0, 0.11)', 'rgba(0, 0, 0, 0.73)', 'rgb(0, 0, 0)'];

const ImageCard: React.FC<ImageCardProps> = ({
  children,
  backgroundImage,
  backgroundColors = defaultBackgroundColors,
  borderRadius = '10px',
  sx = {},
  ...props
}) => {
  const gradient = `linear-gradient(to bottom, ${backgroundColors.join(', ')})`;

  return (
    <Box sx={{
      background: backgroundImage ? `url(${backgroundImage}) no-repeat center center / cover` : gradient,
      borderRadius: borderRadius,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: 'white',
      position: 'relative',
      zIndex: 1,
      minHeight: '60vh',
      maxHeight: '100vh',
      padding: '3rem 2rem',
      textAlign: 'start',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      '&::before': {
        content: '""',
        borderRadius: borderRadius,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: gradient,
        zIndex: -1,
      },
      ...sx
    }} {...props}>
      {children}
    </Box>
  );
}

export default ImageCard;
