// src/components/StepsSection.tsx
import React from 'react';
import Icon1 from '../assets/stepIcons/1.svg'
import Icon2 from '../assets/stepIcons/2.svg'
import Icon3 from '../assets/stepIcons/3.svg'
import Icon4 from '../assets/stepIcons/4.svg'
import Icon5 from '../assets/stepIcons/5.svg'
import Icon6 from '../assets/stepIcons/6.svg'
import './ImportProcess.css';
import {Box, Typography} from "@mui/material";

const icons = [
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
];

const steps = [
  {
    title: 'Свяжитесь с нами',
    description: 'Обсудим детали'
  },
  {
    title: 'Подбираем лучшего поставщика',
    description: 'Заключаем внешнеторговый контракт'
  },
  {
    title: 'Выкупаем товар у производителя/поставщика',
    description: 'Выполняем декларирование груза'
  },
  {
    title: 'Отгружаем товар',
    description: 'Предоставляем сопроводительную и прочую документацию в соответствии с законодательством'
  },
  {
    title: 'Осуществляем таможенное оформление',
    description: 'Выполняем все необходимые процедуры'
  },
  {
    title: 'Уплачиваем необходимые пошлины/налоги',
    description: 'Получаем разрешительную документацию'
  }
];

const StepsSection = () => {
  return (
    <Box sx={{padding: {lg: "10rem 10rem 15rem 15rem", xs: "3rem"}}}>
      <Box sx={{display: {lg: "none", xs: "flex"}, gap: ".3rem", justifyContent: "center"}}>
        <Typography>
          <b>Связываетесь с нами, </b>{" "}обсуждаем детали
        </Typography>
      </Box>
      <Box className="container" sx={{display: {lg: "flex", md: "none", xs: "none"}}}>
        {steps.map((step, index) => (
          <div key={index} className="step">
            <img src={icons[index]} className="icon" alt=""/>
            <span className="dot"></span>
            <div className="details">
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default StepsSection;
