// src/components/CargoTransportationSection.tsx
import React from 'react';
import styled from 'styled-components';

import bgImage from '../assets/transplortation_background.png';
import logo from '../assets/icons/logo.png';
import {Grid, Typography} from "@mui/material";

const CargoTransportationSection: React.FC = () => (
  <Grid sx={{
    background: {lg: `url(${bgImage}) 150% 200% no-repeat`, xs: `url(${bgImage}) 0% 100% no-repeat`},
    backgroundSize: {lg: '', xs: 'contain'},
    paddingY: "50px",
    paddingX: {lg: "12rem", md: "6rem", xs: "1rem"},
    display: "flex",
    maxWidth: "100vw",
    height: {lg: "100%", xs: "115vh"},
    justifyContent: "start",
    alignItems: "center",
    flexWrap: "wrap",
    flexDirection: "row",
    textAlign: "left",
  }}>

    <Grid container lg={6} gap={4} display="flex" flexDirection="column">
      <Grid lg={12} mb={6} sx={{
        display: {lg: "block", xs: "none"}
      }}>
        <img src={logo} style={{aspectRatio: "initial", width: "20%"}} alt="Asia Trade Partners"/>
      </Grid>
      <Typography sx={{
        fontSize: {lg: "3rem", md: "2rem", xs: "2rem"}
      }} fontWeight={600}>Грузоперевозки</Typography>
      <Grid lg={12}>
        <Typography sx={{
          fontSize: {lg: "2rem", xs: "1.5rem"},
          lineHeight: {lg: "2rem", xs: "1.5rem"},
        }} fontWeight={600}>Работаем везде, где удобно клиентам</Typography>
      </Grid>
      <Grid container ml={2} flexDirection="column" gap={2} lg={12}>
        <Typography variant="body1" color="#181818" fontWeight="400">Десятки таможенных постов по всему Казахстану
          ежедневно</Typography>
        <Typography variant="body1" color="#181818" fontWeight="400">Выбор таможенного поста с учетом задачи логистики и
          особенностей товара</Typography>
      </Grid>
      <Typography variant="h6" fontWeight={600}>5 континентов</Typography>
      <Grid container ml={2} flexDirection="column" gap={2} lg={12}>
        <Typography variant="body1" color="#181818">Контейнерные перевозки из Китая</Typography>
        <Typography variant="body1" color="#181818">Доставка грузов из Европы, США, Азии, Латинской Америки, Африки,
          Ближнего Востока и
          стран
          СНГ</Typography>
      </Grid>
    </Grid>

    <Grid container justifyContent="center" lg={6} mb="auto" display="flex" width="100%"
          sx={{
            mt: {lg: 0, xs: "1.5rem"},
            flexDirection: "column",
          }}
          height="100%">
      <Typography sx={{
        fontSize: {lg: "1.5rem", xs: "1rem"},
        lineHeight: {lg: "2rem", xs: "1.5rem"},
      }} fontWeight={600}>
        Мы осуществляем доставку импортных и экспортных грузов следующим транспортом:
      </Typography>
      <Typography sx={{
        mb: "auto",
        color: {lg: "#2DC26B", xs: "#181818"},
        fontSize: {lg: "1.5rem", xs: "1rem"},
        lineHeight: {lg: "2rem", xs: "1.5rem"},
      }} fontWeight={600} color="#2DC26B">авиатранспортом,
        ж/д транспортом, морским транспортом, автотранспортом</Typography>
    </Grid>
  </Grid>
);

export default CargoTransportationSection;
