// src/components/CustomsBrokerSection.tsx
import React from 'react';
import styled from 'styled-components';

import brokerBg from '../assets/broker_backgournd.png';
import circleImg1 from '../assets/circle1.jpg';
import circleImg2 from '../assets/circle2.jpg';
import circleImg3 from '../assets/circle3.jpg';
import {Box, Button, Typography} from "@mui/material";
import ImageCard from './ImageCard';

const ContentContainer = styled.div`
  padding: 50px 20px;
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  justify-content: center;
`;

const InfoCard = styled.div`
  text-align: left;
  display: flex;
  gap: 1.5rem;
  padding: 20px;
  border-radius: 10px;
`;

const CircleImage = styled.img`
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  object-position: top;
`;


const CustomBrokerSection: React.FC = () => (
  <Box sx={{
    padding: {lg: "50px 20px", sm: "2rem 3rem", xs: "2rem 1rem"},
    display: "flex",
    gap: "3rem",
    flexWrap: "wrap",
    justifyContent: "center",
  }}>
    <Box display="flex" flexDirection="column" sx={{
      width: {lg: "auto", xs: "100%"},
    }}>
      <ImageCard backgroundImage={brokerBg}>
        <Typography variant="h3" sx={{
          fontSize: {md: "2.5rem", xs: "2rem"},
        }} fontWeight={600}>Таможенный <br/>брокер</Typography>
        <Box mt="auto">
          <Typography variant="h6" mb="1rem">Помогаем оформлять:</Typography>
          <Box px="1rem" display="flex" flexDirection="column" gap={1}>
            <Typography color="gray">Импорт и экспорт</Typography>
            <Typography color="gray">Процедуры переработки</Typography>
            <Typography color="gray">Временный ввоз и вывоз</Typography>
            <Typography color="gray">Ввоз в уставной капитал</Typography>
            <Typography color="gray">Нестандартные таможенные процедуры</Typography>
          </Box>
        </Box>
      </ImageCard>
    </Box>
    <Box sx={{
      width: {lg: "30%", xs: "100%"},
    }}>
      <Box sx={{
        textAlign: {lg: "left", xs: "center"},
        display: "flex",
        flexDirection: {lg: "row", xs: "column"},
        gap: {lg: "1.5rem", xs: "1rem"},
        padding: "20px",
        borderRadius: "10px",
        alignItems: {lg: "start", xs: "center"},
      }}>
        <CircleImage src={circleImg1} alt="Customs Broker 1"/>
        <Typography fontWeight={600} sx={{alignContent: "center", alignSelf: "center", width: {lg: "60%", xs: "100%"}}}>7 таможенных брокеров
          с большим опытом
          работы</Typography>
      </Box>
      <Box sx={{
        textAlign: {lg: "left", xs: "center"},
        display: "flex",
        flexDirection: {lg: "row", xs: "column-reverse"},
        gap: {lg: "1.5rem", xs: "1rem"},
        padding: "20px",
        borderRadius: "10px",
        alignItems: {lg: "start", xs: "center"},
      }}>
        <Typography fontWeight={600} width={"80%"} sx={{alignContent: "center", alignSelf: "center"}}>Стаж работы наших специалистов в
          области таможенного
          оформления от 25 лет до 7 лет</Typography>
        <CircleImage src={circleImg2} alt="Customs Broker 2"/>
      </Box>
      <Box sx={{
        textAlign: {lg: "left", xs: "center"},
        display: "flex",
        flexDirection: {lg: "row", xs: "column"},
        gap: {lg: "1.5rem", xs: "1rem"},
        padding: "20px",
        borderRadius: "10px",
        alignItems: {lg: "start", xs: "center"},
      }}>
        <CircleImage src={circleImg3} alt="Customs Broker 3"/>
        <Typography fontWeight={600} width={"60%"} sx={{alignContent: "center", alignSelf: "center"}}>Львиную долю занимают бывшие сотрудники
          таможенных
          органов</Typography>
      </Box>
    </Box>
    <Box width="100%" display="flex"
         sx={{
           justifyContent: {lg: "start", xs: "center"}
         }}>
      <Button variant="contained" color="success" sx={{ml: {lg: "19%", xs: "0"}, p: "1rem 2rem"}}>Получить
        консультацию</Button>

    </Box>
  </Box>
);

export default CustomBrokerSection;
