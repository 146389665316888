// src/components/NewsSection.tsx
import React from 'react';
import {Box, Grid, Typography} from "@mui/material";

const items = [
  {
    "image": "https://placehold.co/470x200",
    "title": "СКОЛЬКО КАЗАХСТАН ЗАНЯЛ У КИТАЯ НА МОДЕРНИЗАЦИЮ ПУНКТОВ ПРОПУСКА",
    "publish_date": "15 марта 2024",
    "links": [
      {"title": "Customs", "link": "#"},
      {"title": "Free Trade", "link": "#"},
      {"title": "Agreement", "link": "#"},
      {"title": "News", "link": "#"},
    ],
    "description": "Директор департамента таможенного контроля комитета государственных доходов Министерства финансов Елдос Саудабаев рассказал, во сколько обходится..."
  },
  {
    "image": "https://placehold.co/470x200",
    "title": "КИЛОМЕТРОВЫЕ ПРОБКИ НА КАЗАХСТАНСКО-УЗБЕКСКОЙ ГРАНИЦЕ ОБЪЯСНИЛИ В КГД",
    "publish_date": "15 марта 2024",
    "links": [
      {"title": "Customs", "link": "#"},
      {"title": "News", "link": "#"}
    ],
    "description": "Директор департамента таможенного контроля комитета государственных доходов Министерства финансов Елдос Саудабаев назвал причину километровых пробок..."
  },
  {
    "image": "https://placehold.co/470x200",
    "title": "ОБНОВЛЕНИЕ ТАМОЖЕННЫХ ПОСТОВ В КАЗАХСТАНЕ: ЧТО ОБЕЩАЮТ ИЗМЕНИТЬ",
    "publish_date": "15 марта 2024",
    "links": [
      {"title": "Customs", "link": "#"},
      {"title": "News", "link": "#"}
    ],
    "description": "Председатель Комитета государственных доходов Жанболат Дуйсембинов ознакомился с ходом модернизации работы таможенного поста..."
  }
];


const NewsSection: React.FC = () => (
  <Grid container flexDirection="column" justifyContent="center" sx={{
    py: {lg: 10, md: 5, xs: 3},
    px: {lg: 37, md: 7, xs: "1rem"},
    gap: {lg: 6, xs: 4}
  }}>
    <Grid sx={{textAlign: "start"}}>
      <Typography sx={{
        fontSize: {lg: "3rem", md: "2rem", xs: "2rem"}
      }} fontWeight={600}>Новости от госорганов</Typography>
    </Grid>
    <Grid container flexDirection="column" gap={3}>
      {items.map((item: any) => (
        <Grid container lg={12} sm={7} xs={12} flexDirection="row" justifyContent="space-between">
          <Grid lg={5}>
            <img src={item.image} style={{borderRadius: "1rem", width: "100%"}} alt={item.title}/>
          </Grid>
          <Grid lg={6} sx={{textAlign: "start"}} display="flex" gap=".5rem" flexDirection="column">
            <Typography fontWeight={600} sx={{
              fontSize: {lg: "1.5rem", xs: "1rem"},
              lineHeight: {lg: "1.5rem", xs: "1.5rem"},
              textTransform: "uppercase"
            }}>{item.title}</Typography>
            <Box>
              <Typography sx={{lineHeight: ".5rem"}} variant="caption">{item.publish_date}</Typography><br/>
              {item.links.map((link: any, linkIndex: number) => (
                <Typography variant="caption"
                            sx={{
                              textDecoration: "underline",
                              lineHeight: ".5rem"
                            }}>{link.title}{linkIndex < item.links.length - 1 ? " | " : ""}</Typography>
              ))}
            </Box>
            <Typography fontSize=".9rem">
              {item.description}
            </Typography>
          </Grid>
        </Grid>
      ))}


    </Grid>
  </Grid>
);

export default NewsSection;
