// src/components/PartnersSection.tsx
import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import partner1 from '../assets/partners/partner1.png';
import partner2 from '../assets/partners/partner2.png';
import {Box, Typography} from "@mui/material";

const PartnersSectionContainer = styled.section`
  padding: 7rem 0;
  background: #EDF6F0;
  text-align: center;
  display: flex;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #2DC26B;
  font-weight: 700;
  margin-bottom: 40px;
`;

const PartnerLogo = styled.img`
  max-height: 100%;
  margin: 10px;
`;

const PartnersSection: React.FC = () => {
  return (
    <Box sx={{
      padding: {lg: "7rem 0", xs: "2.5rem 1rem"},
      background: "#EDF6F0",
      textAlign: "center",
      display: {lg: "flex", xs: "column"},
    }}>
      <Box sx={{
        textAlign: {lg: "start", xs: "center"},
        width: {lg: "35%", xs: "100%"},
        paddingLeft: {lg: "10rem", xs: "0"},
        margin: {lg: "3rem 0", xs: "0"},
        display: "block",
      }}>
        <Typography sx={{
          fontSize: {lg: "2.5rem", xs: "1.6rem"},
          marginBottom: "20px",
          color: "#333",
          fontWeight: 600
        }}>Наши партнеры и клиенты</Typography>
        <Box sx={{display: {lg: "block", xs: "none"}}}>
          <Subtitle>В период с 2020 года было заключено 428 договоров с различными УВЭД</Subtitle>
        </Box>
      </Box>
      <Box sx={{
        display: "flex", overflowX: "scroll",
        "&::-webkit-scrollbar": {
          display: "none"
        }
      }}>
        <div>
          <PartnerLogo src={partner1} alt="Partner 1"/>
        </div>
        <div>
          <PartnerLogo src={partner2} alt="Partner 2"/>
        </div>
        <div>
          <PartnerLogo src={partner1} alt="Partner 3"/>
        </div>
        <div>
          <PartnerLogo src={partner2} alt="Partner 4"/>
        </div>
      </Box>
    </Box>
  );
};

export default PartnersSection;
