// src/components/CustomsServicesSection.tsx
import {Box, Grid, Typography} from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import bgImage from '../assets/custom_services_background.jpg'
import logo from '../assets/icons/logo.png';

const SectionContainer = styled.section`
  padding: 50px 20px;
  background: url(${bgImage}) no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    border-radius: 10px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: -1;
  }
`;

const ContentContainer = styled.div`
  background: rgba(255, 255, 255, 0.13);
  color: white;
  padding: 30px;
  border-radius: 10px;
  z-index: 1;
  text-align: start;
`;
const items = [
  'Таможенная очистка импортных грузов',
  'Таможенная очистка экспортных грузов',
  'Подбор кодов ТНВЭД, анализ документов',
  'Расчет таможенных платежей и налогов',
  'Таможенная очистка в иных таможенных процедурах',
  'Таможенная очистка транзитных грузов',
  'Оформление таможенных деклараций',
  'Декларирование на постах таможни РК',
  'Представительство и участие в досмотре и осмотре товаров в местах таможенного оформления',
  'Оформление таможенного транзита',
  'Классификация товара в соответствии с ТН ВЭД',
  'Расчет таможенных платежей',
  'Представление интересов клиента в таможенных органах',
  'Консультация текущих и потенциальных клиентов по вопросам таможенного оформления и ВЭД',
];

const CustomServicesSection: React.FC = () => (
  <SectionContainer>
    <ContentContainer>
      <Grid container sx={{fontSize: {lg: "2rem", md:"1.5rem", xs: ".5rem"}, gap: {md: "1rem", xs:".1rem"} }} flexDirection="column">
        {items.map((text) => (<Grid display="flex" gap={2}>
          <Typography alignSelf="center">•</Typography><Typography sx={{fontSize: {md: "1.5rem", sm:"1rem", xs: ".8rem"}, alignSelf:"center"}}>{text}</Typography>
        </Grid>))}
      </Grid>
    </ContentContainer>
    <Box position="absolute" sx={{display:{sm: "block", xs:"none"} }} right={0} bottom="2rem">
      <img src={logo} style={{aspectRatio: "initial", width: "50%"}} alt="Asia Trade Partners"/>
    </Box>
  </SectionContainer>
);

export default CustomServicesSection;
